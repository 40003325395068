import 'react-quill/dist/quill.snow.css';

import { Button, message } from "antd";
import { EventsApiService } from 'features/events/api/events-api.service';
import { useCreateDisclaimer } from 'features/events/use-cases/create-disclaimer';
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useParams } from 'react-router-dom';


const EventDisclaimerEditor = ({ setEdit }: { edit: boolean, setEdit: (newValue: boolean) => void }) => {
    const [code, setCode] = useState<string>("");
    const params = useParams();

    function decodeHTMLEntities(text: string) {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = text;

        return textArea.value;
    }

    const handleProcedureContentChange = (
        content: React.SetStateAction<string>,
        // delta: any,
        // source: any,
        // editor: any
    ) => {
        setCode(content);
    };
    const { mutate, isLoading } = useCreateDisclaimer();
    const formData: any = {
        // Id:id,
        EventId: params.id ? +params.id : 0,
        Disclaimer: code
    }

    const handleUpdate = () => {
        const strippedContent = code.replace(/<\/?[^>]+(>|$)/g, "").trim();

        // Check if strippedContent is empty or just whitespace
        if (!strippedContent) {
            message.error('Content is empty| Please type something...'); // Or handle the error as needed

            return;
        }
        mutate(formData);
        setEdit(false);
    };

    const getDisclaimer = async () => {
        const apires = await EventsApiService().getEventDisclaimer({ 'event_id': params.id } as any);
        setCode(decodeHTMLEntities(apires?.items[0]?.Disclaimer));
    };


    useEffect(() => {
        getDisclaimer();
    }, [])


    return (

        <>

            <ReactQuill
                className="editor-large"
                theme="snow"
                value={code || ''}
                onChange={handleProcedureContentChange}
                modules={EventDisclaimerEditor.modules}
                formats={EventDisclaimerEditor.formats}
                // dangerouslySetInnerHTML={{ __html: code }}
                // readOnly={!edit} 
                style={{ marginBottom: 10 }}
            />
            <Button loading={isLoading}
                onClick={handleUpdate} type='primary'
            >Save</Button>
        </>


    );
};

EventDisclaimerEditor.modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, false] }], // Select dropdown for heading
        [{ 'font': [] }], // Select dropdown for font size
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'align': [] }], // Select dropdown for text alignment
        [{ 'color': [] }, { 'background': [] }], // Select dropdowns for text and background color
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        matchVisual: true,
    }
};

EventDisclaimerEditor.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video', 'align', 'color', 'background'
];



export default EventDisclaimerEditor;



